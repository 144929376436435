<template>
    <!--编辑页与详情页相同-->
    <div class="SelfRefundDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form
                    :id="formId"
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: right"
                    size="small"
                    label-width="85px"
                >
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="交易机构">
                                <el-input v-model="form.deptName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建人">
                                <el-input v-model="form.creator" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建日期">
                                <el-date-picker v-model="form.createTime" value-format="yyyy-MM-dd" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="退款收银员">
                                <el-input v-model="form.cashier" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="退款类型">
                                <el-input v-model="form.payTemplateName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="会员手机号">
                                <el-input v-model="form.memberMobile" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="会员姓名">
                                <el-input v-model="form.memberName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="实退金额">
                                <el-input :value="form.realRefundMoney | money" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="状态">
                                <el-input :value="form.settleStatus | settleStatus" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="备注">
                                <el-input
                                    v-model="form.remarks"
                                    type="textarea"
                                    maxlength="200"
                                    show-word-limit
                                    :rows="3"
                                    style="width: 400%"
                                    readonly
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div style="height: 32px">
                <el-button style="float: right; margin-top: 3px" size="mini" @click="handlePrint">打印</el-button>
            </div>
        </el-card>
        <el-card style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
                :id="tableId"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="sku.name" label="商品名称" width="140" />
                <el-table-column prop="sku.bars" label="条码" width="120" />
                <el-table-column prop="sku.specs" label="规格" width="100" />
                <el-table-column prop="sku.unit" label="单位" width="80" />
                <el-table-column prop="sku.brand" label="品牌" width="100" />
                <el-table-column label="退货数量" width="120" prop="refundCounts" />
                <el-table-column label="零售单价" width="120" prop="retailPrices">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.retailPrices | money }}</span></template
                    >
                </el-table-column>
                <el-table-column label="退货金额" width="120" prop="refundMoney">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.refundMoney | money }}</span></template
                    >
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'SelfRefundDetail',
    props: ['form'],
    data() {
        return {
            url: {
                queryGoods: '/goods/goods/list_c',
            },
            meta: {
                types: [],
            },
            codes: [],
            tableData: [],
            //id标识
            formId: 'form',
            tableId: 'table',
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        handlePrint() {
            this.printIdSelectorHtml(
                [
                    { id: this.formId, type: 'form' },
                    { id: this.tableId, type: 'table' },
                ],
                this.$route.meta.title || ''
            );
        },
        handleQuery() {
            const _this = this;
            UrlUtils.QueryGoodsInfoByCodes(_this, _this.url.queryGoods, { codes: _this.form.goodsCode }, (data) => {
                data.forEach((v, i) => {
                    v.refundCounts = this.form.refundCounts[i];
                    v.retailPrices = this.form.retailPrices[i];
                    v.refundMoney = this.form.refundMoney[i];
                });
                _this.tableData = data;
            });
        },
    },
    filters: {
        settleStatus(settleStatus) {
            if (typeof settleStatus == 'undefined') {
                return '';
            }
            if (settleStatus == '0') {
                return '待结算';
            }
            if (settleStatus == '1') {
                return '已结算';
            }
        },
    },
};
</script>
<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
